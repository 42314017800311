/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.App {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background: #AA076B;
    background: -webkit-linear-gradient(to right, #61045F, #AA076B);
    background: linear-gradient(to right, #61045F, #AA076B);
}

.react-chatbot-kit-chat-container {
    width: 400px;
}

.react-chatbot-kit-chat-bot-message {
    margin-left: 10px;
}

.react-chatbot-kit-chat-bot-message {
    background-color: #2898ec;
}

.react-chatbot-kit-chat-bot-message-arrow {
    border-right: 8px solid #2898ec;
}

.react-chatbot-kit-chat-btn-send {
    background-color: #2898ec;
}

.ADE {
    background-color: #AA076B;
    width: 40px;
    height: 40px;
    display: grid;
    place-items: center;
    color: white;
    border-radius: 100%;
}

.start-btn {
    background-color: #FE5900;
    color: white;
    padding: .5rem 1rem;
    border-radius: 999px;
    border: none;
    cursor: pointer;
    margin-left: 3rem;
}

.slow-btn {
    margin-left: 1rem;
}

.img-component {
    margin-left: 3rem;
    position: relative;
}

.img-container {
    width: 185px;
    height: 185px;
    border: 2px solid #61045F;
    overflow: hidden;
    border-radius: 5px;
}

.img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.img-component a {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
    background-color: #61045F;
    color: white;
    text-decoration: none;
    padding: .5rem 1rem;
}

.img-component a:hover {
    background-color: #61045f5e;
} */




.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.rsc-container {
  width: 450px !important;
  font-family: "Gill Sans", sans-serif!important;
 
}
.rsc-header{
  background: #1A63C6 !important;
}
/* .hFDZzq{
  background: #1A63C6 !important;
} */
.rsc-ts-bot .rsc-ts-bubble{
  background: #1A63C6 !important;
}
.rsc-os-option-element{
  background: #1A63C6 !important;
}
.rsc-ts-image{
  padding:0 !important;
}
.rsc-header-title{
  color: #ffffff !important;
  font-weight:600 !important;
}
.rsc-float-button {
  background: transparent !important;
  background-size: cover !important;
  background-image: url('../public/images/tintin.gif') !important;
  animation: zoom 2s ease-in-out infinite;
  
}
@keyframes zoom {

  0%,
  100% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(1.3);
    /* Adjust the zoom level as needed */
  }
}

.rsc-float-button >svg {
  display:none !important;
}
.rsc-float-button {
  bottom: 100px !important;
    right: 60px !important;
}